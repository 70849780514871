



















































































import {Component, Vue} from 'vue-property-decorator';
//@ts-ignore
import VLazyImage from "v-lazy-image/v2";
import TemplateEditor from "@/components/auth/register/TemplateEditor.vue";
import AutoGenRegister from "@/components/auth/AutoGenRegister.vue";

interface PreOnboardingGoal {
  items: Array<string>;
  goal: string;
  id: number;
  name: string;
  activity: string;
  color: string
}

@Component({
  components: {VLazyImage, AutoGenRegister},
})
export default class PreOnboardingTemplates extends Vue {
  loading: boolean = false;
  showRegister = false;

  get numberOfGoalsAdded() {
    return Object.keys(this.$store.state.pre_onboarding_goals).length;
  }

  get preOnboardingGoal() {
    return this.$store.state.pre_onboarding_goals;
  }

  get preOnboardingGoalsLength() {
    return Object.keys(this.$store.state.pre_onboarding_goals).length;
  }

  get selectedColor() {
    if(!this.preOnboardingGoalsLength) {
      return this.preSetColors[0];
    } else {
      return this.preSetColors[this.preOnboardingGoalsLength];
    }
  }

  presetGoal: Object ={
    id: 666,
    name: 'Get organized',
    items: [
      'Declutter my desk',
      'Clear my inbox',
      'Plan my week',
      'Invite a collaborator'
    ]
  }

  preSetColors: Array<string> = [
    '#773F75',
    '#4E6068',
    '#5B507A',
    '#7B7343',
    '#654C4F',
    '#316863',
    '#66737a',
    '#546356',
    '#1F4730',
    '#642B2C',
    '#2E294E',
    '#820263',
    '#64024C',
    '#773F75',
  ];


  // specificGoals: Array<{ id: number, name: string, imageId: number, items: Array<any> }> = [
  //   // {
  //   //   "id": 999,
  //   //   "name": "New goal",
  //   //   "imageId": 517,
  //   //   "items": [
  //   //     "Plan tomorrow’s tasks before bed",
  //   //     "Plan todays's tasks",
  //   //     "Create milestone goals and set dealines",
  //   //     "Find an accountability partner",
  //   //     "Break down biggers tasks into subtasks",
  //   //     "Prioritize current tasks",
  //   //     "Work on one task for 2 minutes",
  //   //   ]
  //   // },
  //   {
  //     "id": 40,
  //     "name": "Todo list",
  //     "imageId": 345,
  //     "items": [
  //       "Plan tomorrow’s tasks before bed",
  //       "Plan todays's tasks",
  //       "Create milestone goals and set dealines",
  //       "Find an accountability partner",
  //       "Break down biggers tasks into subtasks",
  //       "Prioritize current tasks",
  //       "Work on one task for 2 minutes",
  //     ]
  //   },
  //   {
  //     "id": 39,
  //     "name": "Health",
  //     "imageId": 4,
  //     "items": [
  //       "Drink one glass of water",
  //       "Do 10 push-ups",
  //       "Stretch for 5 minutes",
  //       "Prepare a healthy snack",
  //       "Book a doctor’s appointment",
  //       "Meditate for 5 minutes",
  //       "Log one meal in a tracker"
  //     ]
  //   },
  //   {
  //     "id": 45,
  //     "name": "Money",
  //     "imageId": 4,
  //     "items": [
  //       "Check your bank balance",
  //       "Cancel an unused subscription",
  //       "Save $5 to a savings account",
  //       "Review one expense",
  //       "Create a budget category",
  //       "Set up auto-pay for a bill",
  //       "List one financial goal"
  //     ]
  //   },
  //   {
  //     "id": 46,
  //     "name": "Chores",
  //     "imageId": 4,
  //     "items": [
  //       "Wash the dishes",
  //       "Take out the trash",
  //       "Wipe down a surface",
  //       "Fold one piece of laundry",
  //       "Organize one drawer",
  //       "Pick up 5 items off the floor",
  //       "Start the laundry"
  //     ]
  //   },
  //   {
  //     "id": 47,
  //     "name": "Work",
  //     "imageId": 4,
  //     "items": [
  //       "Respond to one email",
  //       "Review tasks on your list",
  //       "Work on a project for 10 minutes",
  //       "Schedule one meeting",
  //       "Check off one completed task",
  //       "Write down a new idea",
  //       "Set a timer for 5 minutes to focus"
  //     ]
  //   },
  //   {
  //     "id": 48,
  //     "name": "Family",
  //     "imageId": 4,
  //     "items": [
  //       "Send a text to a family member",
  //       "Ask a loved one how their day was",
  //       "Plan a family meal",
  //       "Check on a relative",
  //       "Set a time for family activity",
  //       "Write down one family memory",
  //       "Compliment a family member"
  //     ]
  //   },
  //   {
  //     "id": 49,
  //     "name": "Cleaning",
  //     "imageId": 4,
  //     "items": [
  //       "Sweep the kitchen",
  //       "Dust the dinner table",
  //       "Clean mirrors",
  //       "Vacuum",
  //       "Empty a trash bin",
  //       "Sanitize your phone screen"
  //     ]
  //   },
  //   {
  //     "id": 50,
  //     "name": "Prioritize my health",
  //     "imageId": 326,
  //     "items": [
  //       "Plan meals for the upcoming week",
  //       "Cook 1 healthy meal",
  //       "Take a 30-minute walk",
  //       "Complete 10 minutes of stretching",
  //       "Sign up for a gym",
  //       "Start meditation practice",
  //       "Go for 20 minute run",
  //       "Complete 1 strength training routine",
  //       "Find an training partner",
  //     ]
  //   },
  //   {
  //     "id": 4,
  //     "name": "Learn to code",
  //     "imageId": 366,
  //     "items": [
  //       "Complete one coding tutorial",
  //       "Create milestone goals and set dealines",
  //       "Find a study buddy",
  //       "Build a simple Todo app",
  //       "Join an online coding community",
  //       "Solve one coding challenge"
  //     ]
  //   },
  //   {
  //     "id": 11,
  //     "name": "Gain confidence",
  //     "imageId": 770,
  //     "items": [
  //       "Write down 5 personal strengths",
  //       "Say one positive affirmation",
  //       "Do one activity outside your comfort zone this week",
  //       "Record and review one small win",
  //       "Cook one healthy meal",
  //       "Wear an outfit that makes you feel confident",
  //       "Complete one challenging but achievable activity",
  //       "Start meditation practice",
  //     ]
  //   },
  //   {
  //     "id": 20,
  //     "name": "Learn to cook",
  //     "imageId": 292,
  //     "items": [
  //       "Cook one new recipe",
  //       "Prep meals for three days",
  //       "Organize your kitchen or pantry",
  //       "Watch one online cooking tutorial",
  //       "Learn one cooking technique",
  //       "Experiment with a new spice or seasoning"
  //     ]
  //   },
  //   {
  //     "id": 37,
  //     "name": "Start meditation practice",
  //     "imageId": 883,
  //     "items": [
  //       "Meditate for 5 minutes",
  //       "Download a meditation app",
  //       "Find an accountability partner",
  //       "Complete one deep breathing session",
  //       "Set up a quiet meditation space",
  //       "Focus on gratitude during one session",
  //       "Try a guided visualization"
  //     ]
  //   },
  //   {
  //     "id": 36,
  //     "name": "Create study plan",
  //     "imageId": 998,
  //     "items": [
  //       "Write down your weekly study goals",
  //       "Break a subject into manageable parts",
  //       "Schedule three study blocks",
  //       "Find a study buddy",
  //       "Use a planner to organize sessions",
  //       "Review and adjust your study plan",
  //       "Take a 15-minute break after studying"
  //     ]
  //   },
  //   {
  //     "id": 35,
  //     "name": "Get Fit",
  //     "imageId": 841,
  //     "items": [
  //       "Do 20 push-ups",
  //       "Take a 30-minute walk or run",
  //       "Drink 2 liters of water today",
  //       "Try a new workout class this week",
  //       "Stretch for 10 minutes before bed",
  //       "Track 10,000 steps",
  //       "Find an accountability partner",
  //     ]
  //   },
  //   {
  //     "id": 8,
  //     "name": "Learn a new language",
  //     "imageId": 442,
  //     "items": [
  //       "Complete one language app lesson",
  //       "Learn five new words and practice using them",
  //       "Find a language buddy and schedule a chat",
  //       "Watch a movie in your target language",
  //       "Write a short diary entry in the language",
  //       "Label 10 household items with their target language names"
  //     ]
  //   },
  //   {
  //     "id": 12,
  //     "name": "Improve work-life balance",
  //     "imageId": 821,
  //     "items": [
  //       "Limit work to set hours",
  //       "Plan a family activity",
  //       "Schedule a ‘me-time’ day",
  //       "Turn off work notifications",
  //       "Set boundaries with colleagues about after-hours"
  //     ]
  //   },
  //   {
  //     "id": 7,
  //     "name": "Set a monthly budget",
  //     "imageId": 22,
  //     "items": [
  //       "Review last month’s bank statements",
  //       "Read one article on investing",
  //       "Set up a savings goal",
  //       "Draft a debt repayment plan",
  //       "Watch one personal finance video",
  //       "Subscribe to a financial newsletter"
  //     ]
  //   },
  //   {
  //     "id": 5,
  //     "name": "Build relationships",
  //     "imageId": 1013,
  //     "items": [
  //       "Call a family member",
  //       "Send a thank-you note to a friend",
  //       "Plan a coffee meetup with a colleague",
  //       "Compliment someone",
  //       "Host a game night or casual meetup",
  //       "Send a birthday card to someone close"
  //     ]
  //   },
  //   {
  //     "id": 10,
  //     "name": "Develop a morning routine",
  //     "imageId": 493,
  //     "items": [
  //       "Wake up at the same time each day",
  //       "Write down 3 things I’m grateful for",
  //       "Do 5 minutes of breathing exercises",
  //       "Avoid checking your phone for the first 30 minutes",
  //       "Drink a glass of water immediately after waking up"
  //     ]
  //   },
  //   {
  //     "id": 1,
  //     "name": "Become more organized",
  //     "imageId": 526,
  //     "items": [
  //       "Declutter your desk",
  //       "Cancel unused subscriptions",
  //       "Do a quick inbox cleanup",
  //       "Set a weekly budget",
  //       "Schedule a 10-minute daily tidying session"
  //     ]
  //   },
  //   {
  //     "id": 6,
  //     "name": "Manage stress",
  //     "imageId": 357,
  //     "items": [
  //       "Practice 10 minutes of meditation",
  //       "Journal about daily thoughts",
  //       "Set aside a 'no work' hour",
  //       "Listen to relaxing music before bed",
  //       "Create a calming evening routine"
  //     ]
  //   },
  //   {
  //     "id": 13,
  //     "name": "Cultivate creativity",
  //     "imageId": 977,
  //     "items": [
  //       "Spend 20 minutes doodling or writing",
  //       "Try a new art medium or craft project",
  //       "Attend a creative workshop",
  //       "Visit a museum or gallery",
  //       "Keep a small sketchbook for random ideas"
  //     ]
  //   },
  //   {
  //     "id": 14,
  //     "name": "Gain Charisma",
  //     "imageId": 1052,
  //     "items": [
  //       "Give a 2-minute speech to yourself in the mirror",
  //       "Memorize and use three new names in a conversation",
  //       "Finish reading one chapter from a book on body language",
  //       "Sign up for a public speaking or improv class",
  //       "Smile and hold eye contact during one interaction",
  //       "Prepare and ask three open-ended questions during a conversation"
  //     ]
  //   },
  //   {
  //     "id": 15,
  //     "name": "Improve diet",
  //     "imageId": 1080,
  //     "items": [
  //       "Add one vegetable to every meal",
  //       "Reduce processed foods intake",
  //       "Limit sugar to one treat a day",
  //       "Drink a glass of water before meals",
  //       "Plan and prep snacks for the week"
  //     ]
  //   },
  //   {
  //     "id": 16,
  //     "name": "Be more mindful",
  //     "imageId": 1001,
  //     "items": [
  //       "Practice 5 minutes of mindfulness",
  //       "Observe breathing every hour",
  //       "Take a mindful walk",
  //       "Set aside a few minutes for self-reflection",
  //       "Listen to a guided mindfulness audio session",
  //       "Spend 10 minutes observing nature"
  //     ]
  //   },
  //   {
  //     "id": 17,
  //     "name": "Advance in my career",
  //     "imageId": 389,
  //     "items": [
  //       "Identify skill gaps and seek training",
  //       "Schedule bi-weekly feedback meetings",
  //       "Shadow a senior team member",
  //       "Research industry trends",
  //       "Build a 5-year career growth plan",
  //       "Network with professionals in your field"
  //     ]
  //   },
  //   {
  //     "id": 18,
  //     "name": "Start a side hustle",
  //     "imageId": 856,
  //     "items": [
  //       "Define target customer profile",
  //       "Post on social media",
  //       "Research competitors’ strengths",
  //       "Set up an email list",
  //       "Create a simple landing page or website",
  //       "Write a short business plan"
  //     ]
  //   },
  //   {
  //     "id": 19,
  //     "name": "Save money",
  //     "imageId": 504,
  //     "items": [
  //       "Create a monthly budget plan",
  //       "Automate a portion of income to savings",
  //       "Reduce eating out to once a week",
  //       "Find one way to cut monthly expenses",
  //       "Compare prices before making purchases"
  //     ]
  //   },
  //   {
  //     "id": 21,
  //     "name": "Increase productivity at work",
  //     "imageId": 5,
  //     "items": [
  //       "Limit multitasking by focusing on one task",
  //       "Batch similar tasks together",
  //       "Set daily priorities before starting work",
  //       "Take regular, short breaks"
  //     ]
  //   },
  //   {
  //     "id": 22,
  //     "name": "Learn an instrument",
  //     "imageId": 1082,
  //     "items": [
  //       "Practice for 15 minutes",
  //       "Watch beginner tutorials",
  //       "Join a local music class or group",
  //       "Memorize basic chords or notes"
  //     ]
  //   },
  //   {
  //     "id": 23,
  //     "name": "Improve mental health",
  //     "imageId": 1062,
  //     "items": [
  //       "Set aside time for a hobby",
  //       "Read for 20 minutes",
  //       "Reach out to a friend",
  //       "Limit social media time",
  //       "Contact a therapist"
  //     ]
  //   },
  //   {
  //     "id": 24,
  //     "name": "Improve self-discipline",
  //     "imageId": 1014,
  //     "items": [
  //       "Create a 'no distractions' workspace",
  //       "Set daily and weekly goals",
  //       "Track time spent on each task",
  //       "Reward yourself for meeting goals"
  //     ]
  //   },
  //   {
  //     "id": 25,
  //     "name": "Be more empathetic",
  //     "imageId": 41,
  //     "items": [
  //       "Listen actively in conversations",
  //       "Spend time with people from diverse backgrounds",
  //       "Ask questions to understand others better",
  //       "Express gratitude towards others"
  //     ]
  //   },
  //   {
  //     "id": 26,
  //     "name": "Start a daily reading habit",
  //     "imageId": 1073,
  //     "items": [
  //       "Read 10 pages each morning or night",
  //       "Set reading goals",
  //       "Join a book club",
  //       "Read summaries of classic literature"
  //     ]
  //   },
  //   {
  //     "id": 27,
  //     "name": "Reduce screen time",
  //     "imageId": 18,
  //     "items": [
  //       "Set screen limits on phone apps",
  //       "Designate tech-free time",
  //       "Read a physical book instead of digital",
  //       "Reduce TV watching to weekends"
  //     ]
  //   },
  //   {
  //     "id": 9,
  //     "name": "Reduce environmental impact",
  //     "imageId": 368,
  //     "items": [
  //       "Switch to reusable grocery bags",
  //       "Limit meat intake to twice a week",
  //       "Recycle paper and plastic",
  //       "Turn off lights when not needed",
  //       "Start composting kitchen waste",
  //       "Use public transportation once a week"
  //     ]
  //   },
  //   {
  //     "id": 28,
  //     "name": "Enhance career skills",
  //     "imageId": 954,
  //     "items": [
  //       "Take a LinkedIn Learning course",
  //       "Join a relevant industry meetup group",
  //       "Write a blog post about industry insights",
  //       "Read one book about leadership",
  //       "Follow an industry expert on social media"
  //     ]
  //   },
  //   {
  //     "id": 29,
  //     "name": "Practice gratitude",
  //     "imageId": 674,
  //     "items": [
  //       "Write down three things you’re grateful for",
  //       "Send a thank-you message to someone",
  //       "Reflect on positive moments at the end of the day",
  //       "Keep a gratitude journal"
  //     ]
  //   }
  // ];

  goals: Array<{ id: number, name: string, imageId: number, items: Array<any> }> = [
    {
      "id": 100,
      "name": "Personal",
      "items": [
        "Plan today's tasks",
        "Meditate for 5 minutes",
        "Clean you desk"
      ],
      "imageId": 517
    },
    {
      "id": 101,
      "name": "Home",
      "items": [
        "Declutter one shelf",
        "Fix a small repair issue",
        "Reorganize one cabinet"
      ],
      "imageId": 755
    },
    {
      "id": 102,
      "name": "Planning",
      "items": [
        "Review tomorrow’s calendar",
        "Add one task to your planner",
        "Set a reminder for an important deadline"
      ],
      "imageId": 4
    },
    {
      "id": 103,
      "name": "For work",
      "items": [
        "Write down one priority for the day",
        "Check progress on one project",
        "Delegate one task"
      ],
      "imageId": 366
    },
    {
      "id": 104,
      "name": "Finances",
      "items": [
        "Review your budget",
        "Save $10 to your emergency fund",
        "Track today’s spending"
      ],
      "imageId": 856
    },
    {
      "id": 105,
      "name": "Fun & trips",
      "items": [
        "Research one destination",
        "Plan one activity for the weekend",
        "Add a new idea to your travel bucket list"
      ],
      "imageId": 342
    },
    {
      "id": 106,
      "name": "Health",
      "items": [
        "Drink a glass of water",
        "Do 15 squats",
        "Stretch your neck and shoulders"
      ],
      "imageId": 770
    },
    {
      "id": 107,
      "name": "School",
      "items": [
        "Review notes for 10 minutes",
        "Submit one assignment",
        "Email a question to your professor"
      ],
      "imageId": 139
    },
    {
      "id": 108,
      "name": "Career",
      "items": [
        "Update one line of your resume",
        "Research one networking event",
        "List one career skill to develop"
      ],
      "imageId": 954
    },
    {
      "id": 109,
      "name": "Travel plans",
      "items": [
        "Check flight prices",
        "List 3 items for your packing list",
        "Save one accommodation option"
      ],
      "imageId": 821
    },
    {
      "id": 110,
      "name": "Appointments",
      "items": [
        "Book one overdue appointment",
        "Set a reminder for your next visit",
        "Confirm a scheduled meeting"
      ],
      "imageId": 7
    },
    {
      "id": 111,
      "name": "Reading list",
      "items": [
        "Add one book to your list",
        "Read 5 pages of your current book",
        "Write a note about a finished book"
      ],
      "imageId": 1073
    },
    {
      "id": 112,
      "name": "Training & fitness",
      "items": [
        "Do a 10-minute workout",
        "Sign up for a fitness class",
        "Watch one tutorial for a new skill"
      ],
      "imageId": 841
    },
    {
      "id": 113,
      "name": "Book writing",
      "items": [
        "Write 100 words",
        "Outline one chapter idea",
        "Edit one paragraph"
      ],
      "imageId": 24
    },
    {
      "id": 114,
      "name": "Business plan",
      "items": [
        "Write down one new idea",
        "Set a deadline for a goal",
        "Draft an elevator pitch"
      ],
      "imageId": 882
    },
    {
      "id": 115,
      "name": "Weekly planning",
      "items": [
        "List 3 priorities for the week",
        "Schedule one recurring task",
        "Review last week’s progress"
      ],
      "imageId": 20
    },
    {
      "id": 116,
      "name": "Hosting a party",
      "items": [
        "Write a guest list",
        "Plan one food or drink item",
        "Choose a date"
      ],
      "imageId": 348
    },
    {
      "id": 117,
      "name": "Podcast",
      "items": [
        "Record a 2-minute test clip",
        "Draft 3 questions for an interview",
        "Choose a topic for the next episode"
      ],
      "imageId": 859
    },
    {
      "id": 118,
      "name": "Chores",
      "items": [
        "Take out the trash",
        "Wipe down a counter",
        "Vacuum one room"
      ],
      "imageId": 998
    },
    {
      "id": 119,
      "name": "Admin",
      "items": [
        "Organize one folder",
        "Reply to one email",
        "Update a document"
      ],
      "imageId": 483
    },
    {
      "id": 120,
      "name": "Work",
      "items": [
        "Review one task",
        "Send one email",
        "Set a timer for 10 minutes to focus"
      ],
      "imageId": 251
    },
    {
      "id": 121,
      "name": "Meetings",
      "items": [
        "Schedule one meeting",
        "Prepare an agenda",
        "Follow up with one attendee"
      ],
      "imageId": 42
    },
    {
      "id": 122,
      "name": "Accounting",
      "items": [
        "Log one expense",
        "Review one bank statement",
        "Send an invoice"
      ],
      "imageId": 328
    },
    {
      "id": 123,
      "name": "Copywriting",
      "items": [
        "Draft a headline",
        "Write 50 words for a project",
        "Edit one paragraph"
      ],
      "imageId": 233
    },
    {
      "id": 124,
      "name": "Client work",
      "items": [
        "Respond to one client email",
        "Prepare a progress report",
        "Schedule a check-in"
      ],
      "imageId": 302
    },
    {
      "id": 125,
      "name": "Hiring pipeline",
      "items": [
        "Review one resume",
        "Schedule one interview",
        "Post a job listing"
      ],
      "imageId": 56
    },
    {
      "id": 126,
      "name": "Annual review",
      "items": [
        "List 3 accomplishments this year",
        "Set a goal for next year",
        "Review feedback from peers"
      ],
      "imageId": 201
    },
    {
      "id": 127,
      "name": "Inbox todo’s",
      "items": [
        "Flag one important email",
        "Reply to one message",
        "Delete one unnecessary email"
      ],
      "imageId": 923
    }
  ];

  handlePreOnboardingGoalClick(goal: PreOnboardingGoal) {
    if(this.$store.state.template_goals_added.includes(goal.id)) {
      this.$store.commit('remove_template_goal_id', goal.id);
      this.$store.commit('removeGoal', goal.id);
    } else {
      this.addGoalsandActivites(goal);
    }
   
  }

  addGoalsandActivites(goal: PreOnboardingGoal) {
    goal.items.forEach(activity => {
      this.$store.dispatch('addActivityToGoal', {
        goalId: goal.id,
        name: goal.name,
        activity: activity,
        color: this.selectedColor,
      });
    });
    this.$store.commit('add_template_goal_id', goal.id);
  }
    
  
    
  isGoalAdded(goalId: number) {
    return this.$store.state.template_goals_added.includes(goalId); 
  }


  openTemplateModal(goal: any, launchedEmpty: boolean) {
    this.$buefy.modal.open({
      component: TemplateEditor,
      //@ts-ignore
      customClass: 'add_goal_from_onboarding',
      props: {
        goal: goal,
        launchedEmpty: launchedEmpty,

      },
      parent: this,
    });
  }

  launchList() {
    if(this.numberOfGoalsAdded) {
      this.loading = true;
      setTimeout(() => {
        this.$router.push({name: 'register-layout' })
      }, 1200);
    } else {
      this.openTemplateModal(this.presetGoal, true)
    }
   
  }

  

}
