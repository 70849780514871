





































































































































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {NorthStar } from "@/typescript/types";
import NorthStarService from "@/NorthStarService"

@Component({
  components: {},
})
export default class TemplateEditor extends Vue {
  @Prop({ default: null, required: false })
  goal!: any;

  @Prop({ default: null, required: false })
  launchedEmpty!: boolean;

  addCustomGoal: boolean = false;
  createActivityInput: string = "";
  createNorthStarInput: string = "";
  selctedColorAdjusted: string | null = null;
  lastAddedNorthstar: NorthStar | null = null;
  service: NorthStarService | null = null;
  addedNorthStars: NorthStar[] = [];
  addedCustomGoalName: string = '';
  goalId: string = '';
  goalName: string = '';
  numberOfActivitesAdded: number = 0;
  loading: boolean = false;
  uniqueId: string = '';
  step: number = 0;

  presetActivities: Array<string> = [
    'Click on the checkmark to complete your todo or activity',
    'Create a new todo and click it to see advanced options',
    'Use the timeline to plan goals, or projects - and schedule them in time by using drag-and-drop',
  ];

  colors: Array<string> = [
    '#754373',
    '#4a677d',
    '#7b7343',
  ];


  preSetColors: Array<string> = [
    '#773F75',
    '#4E6068',
    '#5B507A',
    '#7B7343',
    '#654C4F',
    '#316863',
    '#66737a',
    '#546356',
    '#1F4730',
    '#642B2C',
    '#2E294E',
    '#820263',
    '#64024C',
    '#773F75',
  ];

  get preOnboardingGoals() {
    return this.$store.state.pre_onboarding_goals;
  }

  get preOnboardingGoalsLength() {
    return Object.keys(this.$store.state.pre_onboarding_goals).length;
  }

  get selectedColor() {
    if(!this.preOnboardingGoalsLength) {
      return this.preSetColors[0];
    } else {
      return this.preSetColors[this.preOnboardingGoalsLength];
    }
  }

  get onboardingObjectives() {
    let objectives = [];

    if (this.goal && this.goal.id && this.preOnboardingGoals[this.goal.id]) {
      objectives = this.preOnboardingGoals[this.goal.id].activities || [];
    } else if (this.uniqueId && this.preOnboardingGoals[this.uniqueId]) {
      objectives = this.preOnboardingGoals[this.uniqueId].activities || [];
    }

    // Filter out null or undefined activities
    return objectives.filter((activity: string) => activity !== null && activity !== undefined);
  }

  get availableActivities() {
    return this.goal.items.filter((activity: string) => !this.onboardingObjectives.includes(activity));
  }

  get isGoalAdded() {
    return this.$store.state.template_goals_added.includes(this.goal.id); 
  }


  addEmptyGoal() {
    this.$store.commit('createGoal', 
      { id: this.addCustomGoal ? this.uniqueId: this.goal.id, 
        name: this.addCustomGoal ? this.createNorthStarInput : this.goal.name, 
        color: this.selectedColor }); 

    if(!this.addCustomGoal) {
      this.$store.commit('add_template_goal_id', this.goal.id);
    }
  
    this.addedCustomGoalName =  this.createNorthStarInput
    this.createNorthStarInput = '';
    this.step = 2;
    this.$nextTick(function () {
      //@ts-ignore ToDo: fix type issue
      this.$refs.createActivityInput.focus();
    });
  }

  addToOnboardingGoals(activity: string, suggestion: boolean, skipped: boolean) {
    if (skipped && this.preOnboardingGoalsLength === 0) {

      this.goalName = "Getting Started";
      const addActivitiesPromises = this.presetActivities.map(presetActivity =>
        this.$store.dispatch('addActivityToGoal', {
          goalId: this.goal.id,
          name: this.goalName,
          activity: presetActivity
        })
      );

      Promise.all(addActivitiesPromises)
        .then(() => {
          this.numberOfActivitesAdded += this.presetActivities.length; 

      
          this.$router.push({ name: 'register-layout' });
          this.closeModal();
        })
        .catch(error => {
          console.error('Error adding activities:', error);
        });

      return; 
    }

    if (this.createActivityInput.trim() !== "" || suggestion) {
      this.$store.dispatch('addActivityToGoal', {
        goalId: this.addCustomGoal ? this.uniqueId : this.goal.id,
        name: this.addCustomGoal ? this.goal.name : this.addedCustomGoalName,
        activity
      })
        .then(() => {
          this.numberOfActivitesAdded++;
          this.createActivityInput = ""; 
          //Don't add the ID if user added a custom goal, as we dont want the checkmark indication
          if(!this.addCustomGoal) {
            this.$store.commit('add_template_goal_id', this.goal.id);
          }
        })
        .catch(error => {
          console.error('Error adding activity:', error);
        });
    }
  }


 

  launchList() {
    this.loading = true;
    setTimeout(() => {
      this.$router.push({name: 'register-layout' })
      this.closeModal()
    }, 1200);
  }

  closeModal() {
    //@ts-ignore
    this.$parent.close();
  }

  handleSkip() {
    if(this.preOnboardingGoalsLength === 0) {
      //If no goals added, add preset data
      this.addToOnboardingGoals('', false, true)
    } else {
      this.$router.push({ name: 'register-layout' });
      this.closeModal();
    }
    
  }

  removeActivityFromGoal(activity: string) {
    if (!this.goal || !this.goal.id) {
      console.error('Goal or Goal ID is undefined');
      return;
    }
    this.$store.dispatch('removeActivityFromGoal', {
      goalId: this.goal.id,
      name: this.goal.name,
      activity
    });
    this.createActivityInput = "";
  }

 

  mounted() {
    this.service = new NorthStarService(this.$apollo);

    //Creating unique id for user created goals
    this.uniqueId = `goal-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
    

    if(this.goal.id === 999) {
      this.addCustomGoal = true;
      this.step = 1;
      this.$nextTick(function () {
      //@ts-ignore ToDo: fix type issue
        this.$refs.createNorthStarInput.focus();
      });
    } else {
      this.addCustomGoal = false;
      this.step = 2;
      if(!this.isGoalAdded && !this.launchedEmpty) {
        this.addEmptyGoal();
      } 
      this.$nextTick(function () {
      //@ts-ignore ToDo: fix type issue
        this.$refs.createActivityInput.focus();
      });
    }
  }
  
}
